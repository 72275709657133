import React from 'react';
import { Icon } from 'semantic-ui-react';

import { TableRow, TableBody, TableCell } from '~ui/Table';

import Time from '~/components/Base/Time';
import ShowingLink from './ShowingLink';
import { ShowingTableItem } from '~/types/showings.types';
import { SHOWING_TYPE_OUTER } from '~/types/showings.types';
import UserLinkWithPreview from '../Users/UserLinkWithPreview';
import ContactLinkWithPreview from '../Contacts/ContactLinkWithPreview';
import EstateLinkWithPreview from '../Estate/EstateLinkWithPreview';
import ListTagsPopup from '~/components/Lists/Contacts/ListTagsPopup';

const ShowingsTableBody = ({ showingsList, hideEstate }: { showingsList: Array<ShowingTableItem>; hideEstate?: boolean }) => (
    <TableBody>
        {showingsList.length > 0 &&
            showingsList.map(
                ({ showing_id, contact, estate, showingTime, updateTime, major_user, estateOuterUrl, showingType, tags_ids }) => {
                    return (
                        <TableRow key={showing_id}>
                            <TableCell>
                                <b>
                                    <ShowingLink showing={{ showing_id, showingTime }} />
                                </b>
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    Актуальность: <Time time={updateTime} />
                                </div>
                            </TableCell>
                            <TableCell>
                                <UserLinkWithPreview user={major_user} />
                            </TableCell>
                            <TableCell>
                                <ContactLinkWithPreview contact={contact} />
                            </TableCell>
                            <TableCell>
                                <ListTagsPopup tags_ids={tags_ids} />
                            </TableCell>
                            {!hideEstate && (
                                <TableCell>
                                    {showingType < SHOWING_TYPE_OUTER && estate && <EstateLinkWithPreview estate={estate} />}
                                    {showingType === SHOWING_TYPE_OUTER && (
                                        <a target="_blank" href={estateOuterUrl} rel="noreferrer">
                                            Внешний объект <Icon name="external" />
                                        </a>
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    );
                }
            )}
    </TableBody>
);

export default ShowingsTableBody;
