import * as showingApi from '~/api/showingApi';

import { Showing, ShowingFilter, ShowingTableItem } from '~/types/showings.types';
import ListStorePrototype from './prototypes/ListStore.prototype';
import { SHOWING_TYPE_INNER, SHOWING_TYPE_MLS } from '~/types/showings.types';
import { HistoryChange } from '~/types/historyChanges.types';

type ShowingItemPropertyType = {
    loadingHistory: boolean;
    history: HistoryChange[];
};

class ShowingStore extends ListStorePrototype<Showing, ShowingTableItem, ShowingItemPropertyType, ShowingFilter> {
    listFilterClear = {
        estate_id: 0,
        enable: true,
        group_id: [],
        major_user_id: [],
        tags_ids: []
    };

    orderBy = 'showingTime';

    constructor() {
        super('showing_id', 'showing', showingApi);
        this.clearFilter();
    }

    validationItem(showing: Showing): Array<string> {
        const errors: Array<string> = [];

        if (!showing.contact_id) {
            errors.push('Заполните контакт');
        }

        if ([SHOWING_TYPE_INNER, SHOWING_TYPE_MLS].includes(showing.showingType) && !showing.estate_id) {
            errors.push('Заполните объект');
        }

        return errors;
    }
}

export default new ShowingStore();
