import fetchApi from '../common/fetchApi';
import { objectToGraphql } from '~/common/graphql';
import { Showing, ShowingFilter, SHOWING_ITEM_AVAILABLE, ShowingTableItem } from '~/types/showings.types';
import { GQMainContactFields, GQMainUserFields } from './contactsApi';
import { GQEstateMainFields } from './estateApi';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { HistoryChange } from '~/types/historyChanges.types';
import { GQHistoryChange } from '~/api/userApi';

const GQShowingFields = `
    showing_id
    description
    createTime
    updateTime
    showingTime
    estate_id
    estate {
        ${GQEstateMainFields}
    }
    major_user_id
    ${GQMainUserFields}
    contact_id
    contact {
        ${GQMainContactFields}
    }
    tags_ids
    showingType
    estateOuterUrl
    enable
`;

export const fetchItem = async (id: number): Promise<Showing> => {
    const graphql = `{
        fetchShowing(showing_id: ${id}) {
            ${GQShowingFields}
        } 
      }`;

    const data: { fetchShowing: Showing } = await fetchApi.getGQ(graphql);
    return data.fetchShowing;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    showingFilter: ShowingFilter,
    controller?: AbortController
): Promise<{ list: Array<Showing>; count: number }> => {
    const graphql = `{
            fetchShowings(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(showingFilter)}) {
                list {${GQShowingFields}}
                count
            } 
          }`;

    const data: { fetchShowings: { list: Showing[]; count: number } } = await fetchApi.getGQ(graphql, controller);
    return data.fetchShowings;
};

export const fetchShowingsByItem = async (
    itemType: SHOWING_ITEM_AVAILABLE,
    item_id: number,
    enable: boolean
): Promise<ShowingTableItem[]> => {
    const graphql = `{
        fetchShowingsByItem(itemType: ${itemType}, item_id: ${item_id}, enable: ${String(enable)}) {
            ${GQShowingFields}
        } 
      }`;

    const data: { fetchShowingsByItem: ShowingTableItem[] } = await fetchApi.getGQ(graphql);
    return data.fetchShowingsByItem;
};

export const saveItem = async (showing_id: number, showing: Showing): Promise<number> => {
    const graphql = `mutation { 
        updateShowing(showing_id: ${showing_id}, showingDiff: ${objectToGraphql(showing)})
    }`;

    const data: { updateShowing: number } = await fetchApi.postGQ(graphql);
    return data.updateShowing;
};

export const createItem = async (showing: Showing): Promise<number> => {
    const graphql = `mutation { 
          addShowing(showingInput: ${objectToGraphql(showing)})
        }`;

    const data: { addShowing: number } = await fetchApi.postGQ(graphql);
    return data.addShowing;
};

export const fetchItemHistory = async (showing_id: number): Promise<HistoryChange[]> => {
    const graphql = `{
        fetchShowingChangeHistory(showing_id: ${showing_id}) {
            ${GQHistoryChange}    
        } 
      }`;

    const data: { fetchShowingChangeHistory: HistoryChange[] } = await fetchApi.getGQ(graphql);
    return data.fetchShowingChangeHistory;
};
