import React from 'react';
import history from '~/history';
import { Link } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';

import commonStore from '~/stores/commonStore';

import { CollapsedMenuProps } from './CollapsedLeftMenu';
import Badge from '@material-ui/core/Badge';

const goToCreatePath = (event: Event, pathname: string) => {
    event.preventDefault();
    event.stopPropagation();

    history.push({
        pathname,
        state: { modal: true, switchFromTab: false }
    });
};

const ExpandedLeftMenu = ({ activeItem, handleItemClick, MenuItems }: CollapsedMenuProps) => (
    <Menu vertical className="crm-leftMenu__menu" size={commonStore.isMobile ? 'large' : null}>
        <Menu.Item as={Link} to="/" title="Главная" name="/" active={activeItem === '/'} onClick={handleItemClick}>
            <Icon name="home" />
            Начальный экран
        </Menu.Item>

        {MenuItems.map(({ icon, title, src, submenu }) => (
            <Menu.Item key={src}>
                <Icon name={icon} />
                {title}
                <Menu.Menu>
                    {submenu &&
                        submenu.map(({ title, src, createPath, badgeCount }, i) => (
                            <Menu.Item
                                as={Link}
                                key={title}
                                to={src}
                                name={src}
                                active={activeItem === src}
                                onClick={handleItemClick}
                                className="crm-leftMenu__menu_withPlus"
                            >
                                <Badge badgeContent={badgeCount} color="secondary">
                                    {title}
                                </Badge>
                                {createPath && (
                                    <Icon
                                        size="large"
                                        link
                                        name="plus circle"
                                        className="crm-leftMenu__menu_withPlusIcon"
                                        title="Добавить"
                                        onClick={e => goToCreatePath(e, createPath)}
                                    />
                                )}
                            </Menu.Item>
                        ))}
                </Menu.Menu>
            </Menu.Item>
        ))}
    </Menu>
);

export default ExpandedLeftMenu;
