import React, { Component } from 'react';
import history from '~/history';
import { Segment } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import showingStore from '~/stores/showingStore';
import authStore from '~/stores/authStore';

import Container from '@material-ui/core/Container';
import ExploreIcon from '@material-ui/icons/Explore';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Button from '~ui/Button';
import Table from '~ui/Table';
import SkeletonTable from '~ui/SkeletonTable';

import ListError from '~/components/ListError';
import FilterShowings from './FilterShowings';
import ShowingsTableHeader from './ShowingsTableHeader';
import ShowingsTableBody from './ShowingsTableBody';
import ListFooter from '../Common/ListFooterNew';
import { ListWrapperProps } from '../ListWrapper';
import ListWrapper from '../ListWrapper';

@observer
class ShowingsList extends Component<ListWrapperProps> {
    handleCreateShowing(): void {
        history.push({
            pathname: `/showings/list/overview/${CREATING_ITEM_ID}`,
            state: {
                modal: true,
                callback: { storeName: 'showingStore', method: 'fetchList' }
            }
        });
    }

    render() {
        const { orderBy, orderDirection, handleSort } = this.props;
        const { loadingList, list, listCount, pagination, listErrors, nextListLoaded } = showingStore;

        return (
            <Container maxWidth="lg">
                <div className="crm-List">
                    <Box display="flex" alignItems="center">
                        <ExploreIcon fontSize="large" style={{ paddingRight: '0.5rem' }} />
                        <Typography variant="h5" style={{ paddingRight: '0.5rem' }}>
                            Показы
                        </Typography>
                    </Box>

                    {authStore.canCreate('showing') && (
                        <div className="crm-List__addButton">
                            <Button startIcon={<ExploreIcon />} primary onClick={this.handleCreateShowing} variant="contained">
                                Добавить
                            </Button>
                        </div>
                    )}

                    {listErrors && <ListError errors={listErrors} />}
                </div>

                <Segment.Group className="crm-Segment" id="crm_ListTop">
                    <FilterShowings />

                    <Segment className="crm-List__segment">
                        <Table size="small" compact>
                            <ShowingsTableHeader orderBy={orderBy} orderDirection={orderDirection} handleSort={handleSort} />

                            {loadingList && list.length === 0 && <SkeletonTable columns={5} rows={10} />}

                            <ShowingsTableBody showingsList={list} />

                            <ListFooter
                                loading={loadingList}
                                colSpan={3}
                                pagination={pagination}
                                listCount={listCount}
                                pageChange={showingStore.pageChange}
                                pageSizeChange={showingStore.pageSizeChange}
                                nextListLoaded={nextListLoaded}
                                scrollToElementId="crm_ListTop"
                            />
                        </Table>
                    </Segment>
                </Segment.Group>
            </Container>
        );
    }
}

export default ListWrapper(showingStore, ShowingsList);
