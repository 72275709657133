import React, { useMemo, useRef } from 'react';
import { observer } from 'mobx-react';

import { ChatListType } from '~/types/chat.types';

import chatStore from '~/stores/chatStore';
import Progress from '~ui/Progress';
import Scrollbar from '~/components/Base/Scrollbar';
import ChatMessagesList from '~/components/Chat/ChatMessagesList';
import ListError from '~/components/ListError';

type ChatMessagesProps = {
    item_id: number;
    chatType: ChatListType;
};

const AdsChatsMessagesList = ({ item_id, chatType }: ChatMessagesProps) => {
    const $ref = useRef<HTMLElement>(null);

    useMemo(() => {
        chatStore.fetchItem(item_id, chatType).then(() => {
            if ($ref.current) {
                $ref.current.scrollTop = 1000000;
            }
        });
    }, [item_id]);

    const { loadingItem, item, errors } = chatStore.getItem(item_id);

    if (loadingItem) {
        return <Progress show={true} />;
    }

    const user_id = 0;

    return (
        <Scrollbar
            suppressScrollX
            containerRef={$container => {
                $ref.current = $container;
            }}
        >
            <ListError errors={errors} />
            {item?.chatType === 'avito' && item.messages && (
                <ChatMessagesList user_id={user_id} messages={[...item.messages]} chatType={item.chatType} />
            )}
            {item?.chatType === 'cian' && item.messages && (
                <ChatMessagesList user_id={user_id} messages={[...item.messages]} chatType={item.chatType} />
            )}
            {item?.chatType === 'domclick' && item.messages && (
                <ChatMessagesList user_id={user_id} messages={[...item.messages]} chatType={item.chatType} />
            )}
        </Scrollbar>
    );
};

export default observer(AdsChatsMessagesList);
