import React, { useEffect, useState } from 'react';

import { WaChatComputeAi } from '~/types/whatsapp.types';
import * as whatsappApi from '~/api/whatsappApi';

import FlareIcon from '@material-ui/icons/Flare';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import ListError from '~/components/ListError';
import Button from '~ui/Button';
import Modal, { ModalContent, useToggle } from '~ui/Modal';
import Grid from '~ui/Grid';

const AiAnaliseBlockLoading = () => {
    return (
        <Grid spacing={2}>
            <Grid.Column width={12}>
                <Typography color="textSecondary">Классификация</Typography>
                <Skeleton animation="wave" />
            </Grid.Column>
            <Grid.Column width={12}>
                <Typography color="textSecondary">Краткое описание</Typography>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
            </Grid.Column>
            <Grid.Column width={12}>
                <Typography color="textSecondary">Вариант продолжения диалога</Typography>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
            </Grid.Column>
        </Grid>
    );
};

const AiAnaliseBlock = ({ analise }: { analise: WaChatComputeAi }) => {
    return (
        <Grid spacing={2}>
            <Grid.Column width={12}>
                <Typography color="textSecondary">Классификация</Typography>
                <Typography variant="h6">{analise.classification}</Typography>
            </Grid.Column>
            <Grid.Column width={12}>
                <Typography color="textSecondary">Краткое описание</Typography>
                <Typography>{analise.about}</Typography>
            </Grid.Column>
            <Grid.Column width={12}>
                <Typography color="textSecondary">Вариант продолжения диалога</Typography>
                <textarea style={{ width: '100%', height: '90px' }}>{analise.possibleAnswers}</textarea>
            </Grid.Column>
        </Grid>
    );
};

const ChatAiModal = ({ item_id, onClose }: { item_id: number; onClose: VoidFunction }) => {
    const [analise, setAnalise] = useState<WaChatComputeAi | null>(null);
    const [errors, setError] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        whatsappApi
            .computeAiMessagesStatus(item_id)
            .then(setAnalise)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    return (
        <Modal maxWidth="md" onClose={onClose} header="ИИ анализ чата">
            <ListError errors={errors} />
            <ModalContent>
                {loading && <AiAnaliseBlockLoading />}
                {analise && <AiAnaliseBlock analise={analise} />}
            </ModalContent>
        </Modal>
    );
};

const ChatAIButton = ({ item_id }: { item_id: number }) => {
    const [showModal, toggleModal] = useToggle();

    return (
        <>
            <Button onClick={toggleModal} icon={<FlareIcon />} color="secondary" tooltip="ИИ анализ"></Button>
            {showModal && <ChatAiModal item_id={item_id} onClose={toggleModal} />}
        </>
    );
};

export default ChatAIButton;
