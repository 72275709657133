import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import showingStore from '~/stores/showingStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import Divider from '~ui/Divider';

import { ItemAddModeWrapperProps } from '../ItemAddModeWrapper';
import EstateAndContactEditing from './EditBlocks/EstateAndContactEditing';
import ShowingReportDetailsEditing from './EditBlocks/ShowingReportDetailsEditing';

import ItemAddModeWrapper from '~/components/Items/ItemAddModeWrapper';
import { ShowingLinkState } from '~/components/Lists/Showings/ShowingLink';
import CreationButtonsSet from '~/components/Items/Common/CreationButtonsSet';

type ShowingReportAddModeProps = RouteComponentProps<{}> & ItemAddModeWrapperProps;

const ShowingReportAddMode = (props: ShowingReportAddModeProps) => {
    const { loadingItem, editingItem } = showingStore.getItem(CREATING_ITEM_ID);

    return (
        <Fragment>
            <EstateAndContactEditing item_id={0} {...editingItem} />
            <Divider />
            <ShowingReportDetailsEditing item_id={0} {...editingItem} />
            <CreationButtonsSet loading={loadingItem} onCreate={props.handleCreate} onClearForm={props.clearForm} />
        </Fragment>
    );
};

export default ItemAddModeWrapper(showingStore, ShowingLinkState, withRouter(observer(ShowingReportAddMode)));
