import React from 'react';
import { Grid } from 'semantic-ui-react';

import Typography from '@material-ui/core/Typography';
import ExploreIcon from '@material-ui/icons/Explore';
import Box from '@material-ui/core/Box';

import LabelArchive from '../Common/LabelArchive';
import LoaderAwait from '~/components/Base/LoaderAwait';

import Comments from '../Common/Comments/index';
import ShowingReportDetailsShow from './ShowBlocks/ShowingReportDetailsShow';
import AddedItemsShow from './ShowBlocks/AddedItemsShow';

const ShowingReportShowMode = ({ loading, showing_id, enable }: { loading: boolean; showing_id: number; enable: boolean }) => {
    return (
        <Grid stackable>
            <Grid.Column width={8}>
                <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
                    <ExploreIcon fontSize="large" />
                    <Typography variant="h6">Показ №{showing_id}</Typography>
                </Box>

                {!loading && !enable && <LabelArchive />}

                <ShowingReportDetailsShow item_id={showing_id} />

                {!loading && <Comments item_id={showing_id} item_type="SHOWING" />}

                <LoaderAwait dimmer active={loading} />
            </Grid.Column>

            <Grid.Column width={8}>
                <AddedItemsShow item_id={showing_id} />
            </Grid.Column>
        </Grid>
    );
};

export default ShowingReportShowMode;
