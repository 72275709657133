import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroller';

import { WAChatMessage } from '~/types/whatsapp.types';
import whatsappStore from '~/stores/whatsappStore';

import Box from '@material-ui/core/Box';

import Progress from '~ui/Progress';
import Scrollbar from '~/components/Base/Scrollbar';

import WAChatMsg from './WAMessage';

const useStyles = makeStyles(() => ({
    root: {
        maxHeight: 'calc(100vh - 190px)',
        marginTop: '50px'
    },
    chatContainer: {
        maxWidth: 795,
        margin: '0 auto'
    }
}));

const matchMessagesList = (messages: WAChatMessage[]): { fromMe: boolean; messages: WAChatMessage[] }[] => {
    return messages.reverse().reduce((acc, waMessage) => {
        const { fromMe } = waMessage.message;
        if (!acc.length || acc[acc.length - 1].fromMe !== fromMe) {
            acc.push({ fromMe, messages: [waMessage] });
        } else {
            acc[acc.length - 1].messages.push(waMessage);
        }
        return acc;
    }, []);
};

export const WhatsappChatMessagesList = ({
    messages,
    userAvatarUrl,
    waContactAvatarUrl
}: {
    messages: WAChatMessage[];
    userAvatarUrl: string;
    waContactAvatarUrl: string;
}) => {
    const classes = useStyles();

    return (
        <Box position="relative">
            <div className={classes.chatContainer}>
                {matchMessagesList(messages).map(({ fromMe, messages }) => (
                    <WAChatMsg
                        key={messages[0].message_id}
                        avatar={fromMe ? userAvatarUrl : waContactAvatarUrl}
                        side={fromMe ? 'right' : 'left'}
                        messages={messages}
                    />
                ))}
            </div>
        </Box>
    );
};

const WAMessagesList = ({ contact_id }: { contact_id: number }) => {
    const classes = useStyles();
    const [mainRef, setRef] = useState<HTMLElement | null>(null);

    const {
        list,
        pagination,
        loadingList,
        filter,
        options: { chats }
    } = whatsappStore.getItemList(contact_id);

    useEffect(() => {
        setTimeout(() => {
            if (mainRef && !loadingList && pagination.activePage === 1) {
                mainRef.scrollTop = 1000000;
            }
        }, 0);
    }, [filter.whatsapp_chat_id, loadingList]);

    const foundIndex = chats.findIndex(({ whatsapp_chat_id }) => whatsapp_chat_id === filter.whatsapp_chat_id);
    const {
        waContact,
        waChat: { user }
    } = chats[foundIndex];

    return (
        <Scrollbar suppressScrollX containerRef={setRef} className={classes.root}>
            <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={() => whatsappStore.loadNextPage(contact_id)}
                hasMore
                loader={
                    <div key="loading" style={{ position: 'relative' }}>
                        <Progress show={loadingList} />
                    </div>
                }
                useWindow={false}
                isReverse
            >
                <WhatsappChatMessagesList messages={list} userAvatarUrl={user.avatarUrl} waContactAvatarUrl={waContact.photoUrl} />
            </InfiniteScroll>
        </Scrollbar>
    );
};

export default observer(WAMessagesList);
