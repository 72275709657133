import React, { useState } from 'react';
import { TableHead, TableRow, TableCell } from '~ui/Table';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { adjustDirection } from '~/stores/helpers/adjustDirection.helpers';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '~ui/Button';

const RequestTableHeader = ({
    orderBy,
    orderDirection,
    handleSort
}: {
    orderBy: string;
    orderDirection: OrderDirectionType;
    handleSort: (name: string) => void;
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const chooseSortDirection = (direction: string) => {
        handleSort(direction);
        handlePopoverClose();
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    onClick={() => handleSort(orderBy === 'createTime' ? 'createTime' : 'updateTime')}
                    sortDirection={orderBy === 'updateTime' ? adjustDirection(orderDirection) : false}
                >
                    Дата&nbsp;
                    {orderBy === 'updateTime' && '(обновления)'}
                    {orderBy === 'createTime' && '(создания)'}
                    <Button icon={<MoreVertIcon />} onClick={handlePopoverOpen} />
                </TableCell>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <MenuItem selected={orderBy === 'updateTime'} onClick={() => chooseSortDirection('updateTime')}>
                        По дате обновления
                    </MenuItem>
                    <MenuItem selected={orderBy === 'createTime'} onClick={() => chooseSortDirection('createTime')}>
                        По дате создания
                    </MenuItem>
                </Menu>
                <TableCell>Статус</TableCell>
                <TableCell>Контакт</TableCell>
                <TableCell>Тэги</TableCell>
                <TableCell>Комментарий</TableCell>
                <TableCell>Ответственный(е)</TableCell>
            </TableRow>
        </TableHead>
    );
};

export default RequestTableHeader;
