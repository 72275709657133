import fetchApi from '../common/fetchApi';
import { objectToGraphql } from '~/common/graphql';
import { WAChatType, WAChatMessage, WAUsedAccount, WaChatComputeAi } from '~/types/whatsapp.types';
import { GQUserMainFields } from '~/api/userApi';

export const GQWhatsappChatMessageFields = `
    message_id
    whatsapp_message_id
    whatsapp_chat_id

    status
    fromMe
    isForwarded
    hasQuotedMsg
    isStarred

    message

    createTime
    enable
`;

export const GQWAMessage = `
    message_id
    whatsapp_chat_id
    message {
        ${GQWhatsappChatMessageFields}
    }
    links
    media {
        mimetype
        originalname
        filename
    }
`;

export const fetchItemList = async (
    contact_id: number,
    filter: { whatsapp_chat_id: number },
    limit: { pageSize: number; offset: number }
): Promise<WAChatMessage[]> => {
    const graphql = `{
        fetchContactWhatsappMessages(contact_id: ${contact_id}, filter: ${objectToGraphql(filter)} limit: ${objectToGraphql(limit)}) {
            ${GQWAMessage}    
        }
      }`;

    const data = await fetchApi.getGQ<{ fetchContactWhatsappMessages: WAChatMessage[] }>(graphql);
    return data.fetchContactWhatsappMessages;
};

export const GQWhatsappContact = `
    contactName
    shortName
    pushname
    photoUrl
`;

const GQWhatsappChatFields = `
    whatsapp_chat_id

    waChat {
        user_id
        user {
            ${GQUserMainFields}
        }
        phone
        createTime
        updateTime
        enable 
    }
    
    waContact {
        ${GQWhatsappContact}        
    }
`;

export const fetchWhatsappChatOptions = async (contact_id: number): Promise<WAChatType> => {
    const graphql = `{
        fetchWhatsappChatOptions(contact_id: ${contact_id}) {
            chats {
                ${GQWhatsappChatFields}
            }    
        }
      }`;

    const data = await fetchApi.getGQ<{ fetchWhatsappChatOptions: WAChatType }>(graphql);
    return data.fetchWhatsappChatOptions;
};

export const fetchWhatsappUsedAccounts = async (): Promise<WAUsedAccount[]> => {
    const graphql = `{
        fetchWhatsappUsedAccounts {
            phone
            enable
            user_id
            user {
                ${GQUserMainFields}
            }  
        }
      }`;

    const data = await fetchApi.getGQ<{ fetchWhatsappUsedAccounts: WAUsedAccount[] }>(graphql);
    return data.fetchWhatsappUsedAccounts;
};

export const computeAiMessagesStatus = async (whatsapp_chat_id: number): Promise<WaChatComputeAi> => {
    const graphql = `{
        computeAiMessagesStatus(whatsapp_chat_id: ${whatsapp_chat_id}) {
            classification
            about
            possibleAnswers
        }
      }`;

    const data = await fetchApi.postGQ<{ computeAiMessagesStatus: WaChatComputeAi }>(graphql);
    return data.computeAiMessagesStatus;
};
