import fetchApi, { grabMessageFromError } from '../common/fetchApi';
import { DocumentDir } from '~/types/documents.types';
import { ITEM_TYPES } from '~/types/notifications.types';
import { GQUserMainFields } from './userApi';
import { DocumentType, DocumentDiff } from '~/types/documents.types';
import { objectToGraphql } from '../common/graphql';

let DIRS_CACHE: Array<DocumentDir>;

export const fetchDocumentDirs = async (): Promise<Array<DocumentDir>> => {
    if (!DIRS_CACHE) {
        const graphql = `{
        fetchDocumentDirs {
            dir_id
            title
        }
      }`;
        const data: { fetchDocumentDirs: DocumentDir[] } = await fetchApi.getGQ(graphql);
        DIRS_CACHE = data.fetchDocumentDirs;
    }
    return DIRS_CACHE;
};

export const fetchDocuments = async (item_id: number, item_type: ITEM_TYPES): Promise<DocumentType[]> => {
    const graphql = `{
        fetchDocuments(item_type: ${item_type}, item_id: ${item_id}) {
            doc_id
            dir_id
            title
            originalname
            filename
            major_user_id
            major_user {
                ${GQUserMainFields}
            }
            createTime
            enable
        }
      }`;

    const data: { fetchDocuments: DocumentType[] } = await fetchApi.getGQ(graphql);
    return data.fetchDocuments;
};

export const updateDocument = async (doc_id: number, docDiff: DocumentDiff): Promise<number> => {
    const graphql = `mutation { 
              updateDocument(doc_id: ${doc_id}, docDiff: ${objectToGraphql(docDiff)})
            }`;

    const data: { updateDocument: number } = await fetchApi.postGQ(graphql);
    return data.updateDocument;
};

export const checkDDUDocuments = async (estate_id: number): Promise<boolean> => {
    const graphql = `{ 
          checkDDUDocuments(estate_id: ${estate_id})
        }`;

    const data = await fetchApi.getGQ<{ checkDDUDocuments: boolean }>(graphql);
    return data.checkDDUDocuments;
};
