import React from 'react';
import { observer } from 'mobx-react';

import callRateStore from '~/stores/cell/callRateStore';

import ListFilterWrapper from '../ListFilterWrapper';
import { ListFilterWrapperProps } from '../ListFilterWrapper';

const FilterCallRates = (props: ListFilterWrapperProps) => {
    return null;
};

export default ListFilterWrapper(callRateStore, observer(FilterCallRates));
