import React, { useState, ReactNode, SyntheticEvent } from 'react';
import { Dropdown, Popup, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import OutsideClick from '../../Base/OutsideClick';
import { DropdownType } from '~/stores/prototypes/ListStore.prototype';
import commonStore from '~/stores/commonStore';
import Modal, { ModalBtnAction, ModalContent } from '~ui/Modal';

export const removeSetFromArray = (removingSet: number[], allArray: number[]): number[] => {
    const adjustedArray = [...allArray];
    removingSet.forEach(type => {
        const foundIndex = adjustedArray.indexOf(type);
        if (~foundIndex) {
            adjustedArray.splice(foundIndex, 1);
        }
    });
    return adjustedArray;
};

type TRichDropdownProps = {
    value: number | string | boolean | number[];
    options: DropdownType[];
    onChange: (event: SyntheticEvent<HTMLElement, Event> & { target: Node }, data: { value: number | string | number[] }) => void;
    renderLabel?: (data: { text: string; value: number }) => { color?: SemanticCOLORS; content: string; icon?: SemanticICONS } | null;
    placeholder: string;
    children: ReactNode;
    onClick?: (event?: SyntheticEvent) => void;
    notMultiple?: boolean;
    shortWidth?: boolean;
    showInModal?: boolean;
};

const RichDropdown = (props: TRichDropdownProps) => {
    const [showPopup, setShowPopup] = useState(false);

    const toggleOpen = (event: SyntheticEvent) => {
        if (props.onClick) {
            props.onClick(event);
        }
        setShowPopup(!showPopup);
    };

    const { isMobile, screenIsShort } = commonStore;
    const { value, options, placeholder, children, notMultiple, shortWidth, showInModal } = props;
    const actions: ModalBtnAction[] = [{ onClick: toggleOpen, label: 'Закрыть', variant: 'text', color: 'secondary' }];

    const TriggerDropdown = (
        <Dropdown
            floating
            fluid={!shortWidth}
            placeholder={placeholder}
            value={value}
            open={false}
            multiple={!notMultiple}
            selection
            options={options}
            onChange={props.onChange}
            onClick={toggleOpen}
            onLabelClick={toggleOpen}
            renderLabel={props.renderLabel || null}
            clearable
            className={`ui form ${isMobile ? 'small' : 'mini'}`}
        />
    );

    if (showInModal || isMobile || screenIsShort) {
        return (
            <>
                {TriggerDropdown}
                {showPopup && (
                    <Modal maxWidth="sm" header={placeholder} fullScreen={isMobile} onClose={toggleOpen} actions={actions}>
                        <ModalContent>{children}</ModalContent>
                    </Modal>
                )}
            </>
        );
    }

    return (
        <Popup trigger={TriggerDropdown} wide="very" open={showPopup}>
            <OutsideClick onOutsideClick={toggleOpen}>{children}</OutsideClick>
        </Popup>
    );
};

export default observer(RichDropdown);
