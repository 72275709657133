import React, { useMemo, Fragment, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Scrollbar from '~/components/Base/Scrollbar';
import history from '~/history';
import InfiniteScroll from 'react-infinite-scroller';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ChatListUser from './ChatListUser';
import ChatCellSkeleton from './ChatCellSkeleton';

import chatStore from '~/stores/chatStore';

import UserCell from './UserCell';
import AdsChatCell from './AdsChatCell';
import WhatsappCell from './WhatsappCell';

import { AdsChatType, ChatListType } from '~/types/chat.types';

import Progress from '~/components/Base/ui/Progress';
import { InfoMessage } from '~ui/Message';

export type ChatTab = {
    label: string;
    value: ChatListType;
};

type ChatsListProps = {
    chatType: ChatListType;
    onSelectChat?: () => void;
    activeItemId: number;
    tabs: ChatTab[];
};

export const ChatTab = withStyles(({ spacing }) =>
    createStyles({
        root: {
            minWidth: 0
        }
    })
)(Tab);

const ChatsList = (props: ChatsListProps) => {
    useMemo(() => {
        if (chatStore.listFilter.user_id) {
            chatStore.changeFilter('type', props.chatType || 'users');
        }
    }, [chatStore.listFilter.user_id]);

    const handleSelectChat = (chatType: ChatListType, chat_id: number, item_id?: number) => {
        history.push(`/chat/${chatType}/${chat_id}${typeof item_id === 'number' ? `/${item_id}` : ''}`);

        if (props.onSelectChat) {
            props.onSelectChat();
        }
    };

    const handleChangeTab = (event: SyntheticEvent, tabName: ChatListType) => {
        chatStore.changeFilter('type', tabName);
    };

    const {
        list,
        loadingList,
        listFilter: { type }
    } = chatStore;
    const { activeItemId } = props;

    return (
        <Fragment>
            <ChatListUser />

            <Tabs
                variant="fullWidth"
                value={type || 'users'}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTab}
                className="crm-Chat__tabs"
            >
                {props.tabs.map(({ label, value }) => (
                    <ChatTab key={value} label={label} value={value} />
                ))}
            </Tabs>

            <Scrollbar>
                <InfiniteScroll
                    pageStart={0}
                    initialLoad={false}
                    loadMore={chatStore.loadNextChats}
                    hasMore={!loadingList}
                    loader={
                        <div key="loading" style={{ position: 'relative' }}>
                            <Progress show={loadingList} />
                        </div>
                    }
                    useWindow={false}
                >
                    <div className="crm-Chat__user">
                        {list.length === 0 && loadingList && [1, 2, 3, 4, 5, 6, 7, 8, 9].map(key => <ChatCellSkeleton key={key} />)}
                        {list.length === 0 && !loadingList && <InfoMessage>Список чатов пуст</InfoMessage>}
                        {list.map((chat, index) => {
                            if ('chat_id' in chat) {
                                return (
                                    <UserCell
                                        key={chat.chat_id}
                                        chat_id={chat.chat_id}
                                        chat={chat}
                                        active={chat.user.user_id === activeItemId}
                                        onSelectUser={handleSelectChat}
                                    />
                                );
                            }

                            if ('ads_chat_id' in chat) {
                                return (
                                    <AdsChatCell
                                        key={chat.ads_chat_id}
                                        chat={chat}
                                        active={chat.ads_chat_id === activeItemId}
                                        chatType={type as AdsChatType}
                                        onSelectChat={handleSelectChat}
                                    />
                                );
                            }

                            if ('whatsapp_chat_id' in chat) {
                                return (
                                    <WhatsappCell
                                        key={chat.whatsapp_chat_id}
                                        chat={chat}
                                        active={chat.whatsapp_chat_id === activeItemId}
                                        onSelectUser={handleSelectChat}
                                    />
                                );
                            }
                        })}
                    </div>
                </InfiniteScroll>
            </Scrollbar>
        </Fragment>
    );
};

export default observer(ChatsList);
