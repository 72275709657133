import React, { Component, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SendIcon from '@material-ui/icons/Send';

import chatStore from '~/stores/chatStore';
import ItemErrors from '../Items/ItemErrors';
import { ChatListType } from '~/types/chat.types';
import TextInput from '~ui/TextInput';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 795
    }
}));

type ChatSendBoxProps = {
    message: string;
    onSend: () => void;
    onChange: (message: string) => void;
    disabled?: boolean;
    label?: string;
};

export const ChatSendBox = ({ message, onSend, onChange, disabled, label = 'Сообщение' }: ChatSendBoxProps) => {
    const classes = useStyles();

    const handleEditMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    return (
        <TextInput
            className={classes.root}
            label={label}
            multiline
            rowsMax={4}
            rows={1}
            margin="normal"
            value={message}
            onChange={handleEditMessage}
            extraLabel={
                <IconButton onClick={onSend} disabled={disabled}>
                    <Tooltip title="Отправить" placement="top">
                        <SendIcon color={!disabled ? 'primary' : 'disabled'} />
                    </Tooltip>
                </IconButton>
            }
        />
    );
};

type ChatSendAreaProps = {
    item_id: number;
    chatType: ChatListType;
};

const ChatSendArea = (props: ChatSendAreaProps) => {
    const [message, setMessage] = useState('');

    useEffect(() => {
        setMessage('');
    }, [props.item_id, props.chatType]);

    const handleSend = () => {
        chatStore.sendMessage(props.item_id, props.chatType, message);
        setMessage('');
    };

    const { item, errors } = chatStore.getItem(props.item_id);

    if (!item) {
        return null;
    }

    return (
        <div className="crm-Chat__footer">
            <ItemErrors errors={errors} />
            <ChatSendBox message={message} onSend={handleSend} onChange={setMessage} disabled={!message.length} />
        </div>
    );
};

export default observer(ChatSendArea);
