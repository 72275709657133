import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ShowingLinkType } from '~/types/showings.types';

type ShowingLinkProps = {
    showing: ShowingLinkType;
};

export const ShowingLinkState = (showing_id: number) => ({
    pathname: `/showings/list/overview/${showing_id}`,
    state: { modal: true, switchFromTab: false }
});

const ShowingLink = ({ showing: { showingTime, showing_id } }: ShowingLinkProps) => {
    return (
        <Link to={ShowingLinkState(showing_id)} title={new Date(showingTime * 1000).toLocaleString()}>
            №{showing_id}, {moment(showingTime * 1000).calendar()}
        </Link>
    );
};

export default ShowingLink;
