import React, { useState, useEffect, useRef } from 'react';
import './chat.less';

import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import ChatIcon from '@material-ui/icons/Chat';
import Toolbar from '@material-ui/core/Toolbar';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import ChatsList, { ChatTab } from './ChatList';
import ChatHeader from './ChatHeader';
import ChatMessages from './ChatMessages';
import commonStore from '~/stores/commonStore';
import { ADS_CHAT_TYPES, ChatListType } from '~/types/chat.types';
import chatStore from '~/stores/chatStore';

const drawerWidth = 315;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0
            }
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
                padding: theme.spacing(2, 0)
            }
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth
        },
        content: {
            flexGrow: 1,
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap'
        }
    })
);

const tabsAds: ChatTab[] = [
    { label: 'Авито', value: 'avito' },
    { label: 'Циан', value: 'cian' },
    { label: 'Домклик', value: 'domclick' }
];

const tabsSelf: ChatTab[] = [
    { label: 'Чаты', value: 'users' },
    { label: 'Whatsapp', value: 'whatsapp' }
];

export default function Chat(props) {
    const {
        match: { params }
    } = props;
    const classes = useStyles({});
    const { chat_type, chatId, itemId }: { chat_type: ChatListType; chatId: string; itemId?: string } = params;

    const [tabs, setTabs] = useState<ChatTab[]>(tabsSelf);

    const isRendered = useRef(false);

    useEffect(() => {
        if (ADS_CHAT_TYPES.includes(chat_type)) {
            setTabs(tabsAds);
        } else {
            setTabs(tabsSelf);
        }

        if (!isRendered.current) {
            isRendered.current = true;
            return;
        }

        if (chatStore.listFilter.type !== chat_type) {
            chatStore.changeFilter('type', chat_type);
        }
    }, [chat_type]);

    const chat_id = Number(chatId);
    const [mobileOpen, setMobileOpen] = useState(Boolean(!chat_id && commonStore.isMobile));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar} color="inherit">
                <Toolbar>
                    <IconButton color="primary" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
                        <ChatIcon />
                    </IconButton>

                    {Number(itemId) > 0 && <ChatHeader item_id={Number(itemId)} chatType={chat_type} />}
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
                {commonStore.isMobile && (
                    <SwipeableDrawer
                        variant="temporary"
                        anchor="left"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        onOpen={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        <ChatsList tabs={tabs} onSelectChat={handleDrawerToggle} chatType={chat_type} activeItemId={chat_id} />
                    </SwipeableDrawer>
                )}
                {!commonStore.isMobile && (
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        variant="permanent"
                        open
                    >
                        <ChatsList tabs={tabs} chatType={chat_type} activeItemId={chat_id} />
                    </Drawer>
                )}
            </nav>
            <main className={classes.content}>{chat_id > 0 && <ChatMessages item_id={chat_id} chatType={chat_type} />}</main>
        </div>
    );
}
