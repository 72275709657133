import React, { useState, useEffect } from 'react';
import * as chatApi from '~/api/chatApi';

import { AdsChatTitle, AdsChatType } from '~/types/chat.types';

import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import ListError from '~/components/ListError';
import LoaderAwait from '~/components/Base/LoaderAwait';

import AdsChatCell from '~/components/Chat/ChatList/AdsChatCell';
import AdsChatsMessagesList from './AdsChatsMessagesList';
import { ChatTab } from '~/components/Chat/ChatList';

const drawerWidth = 315;

const tabs: { label: string; value: AdsChatType }[] = [
    { label: 'Авито', value: 'avito' },
    { label: 'Циан', value: 'cian' },
    { label: 'Домклик', value: 'domclick' }
];

const EstateAdsChatsMode = ({ estate_id }: { estate_id: number }) => {
    const [chatList, setChatList] = useState<AdsChatTitle[]>([]);
    const [loadingList, setLoading] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);

    const [chat_type, setChatType] = useState<AdsChatType>('avito');

    const [chat_id, setChatId] = useState<number | null>(null);

    useEffect(() => {
        setLoading(true);
        setChatList([]);
        setErrors([]);
        chatApi
            .fetchAdsChatsByEstate(chat_type, estate_id)
            .then(list => {
                setChatList(list);
                if (chat_id === null && list.length) {
                    setChatId(list[0].ads_chat_id);
                }
            })
            .catch(setErrors)
            .finally(() => setLoading(false));
    }, [estate_id, chat_type]);

    return (
        <Paper elevation={2} style={{ width: '100%', height: 'calc(100vh - 190px)', maxHeight: 'calc(100vh - 190px)', display: 'flex' }}>
            <Box component="nav" width={drawerWidth}>
                <Paper style={{ height: '100%', maxHeight: '100%', display: 'flex', flexDirection: 'column' }} elevation={3}>
                    <Tabs
                        variant="fullWidth"
                        value={chat_type}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(_, type) => setChatType(type)}
                        className="crm-Chat__tabs"
                        style={{ flex: 0 }}
                    >
                        {tabs.map(({ label, value }) => (
                            <ChatTab key={value} label={label} value={value} />
                        ))}
                    </Tabs>

                    <LoaderAwait linear active={loadingList} color="secondary" />
                    <ListError errors={errors} />

                    <div className="crm-Chat__user" style={{ flex: 1, overflowY: 'auto' }}>
                        {chatList.map((chat, index) => {
                            if (!chat) return <div key={index}>Ошибка в чате</div>;

                            const chatId = chat.ads_chat_id;

                            return (
                                <AdsChatCell
                                    key={chatId}
                                    chat={chat}
                                    active={chatId === chat_id}
                                    chatType={chat_type}
                                    onSelectChat={() => setChatId(chatId)}
                                />
                            );
                        })}
                    </div>
                </Paper>
            </Box>

            <Box flex={1} position="relative">
                {chat_id !== null && <AdsChatsMessagesList item_id={chat_id} chatType={chat_type} />}
            </Box>
        </Paper>
    );
};

export default EstateAdsChatsMode;
