import React, { Fragment } from 'react';
import { Icon, List, Popup } from 'semantic-ui-react';
import { TableCell, TableRow, TableBody } from '~ui/Table';

import cellCallStore from '~/stores/cell/cellCallStore';
import callEventStore from '~/stores/cell/callEventStore';

import { secondsToMS } from '~/common/time';
import history from '~/history';

import ContactLinkWithPreview from '../Contacts/ContactLinkWithPreview';
import { CALL_EVENT_ITEM_TYPE_CONTACT, CallEvent } from '~/types/events.types';
import EstateLinkWithPreview from '../Estate/EstateLinkWithPreview';
import { ESTATE_BASE_OWNERS } from '~/types/estate.types';
import UsersLinkList from '../Users/UsersLinkList';
import CallEventLink from './CallEventLink';
import PhonesList from '~/components/Base/PhonesList';
import DownloadLink from '../CellCalls/SubBlocks/DownloadLink';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';

import Checkbox from '~ui/Checkbox';
import Button from '~ui/Button';

type CellCallTableBodyProps = {
    callEventsList: CallEvent[];
};

const setCellCallFilter = (phone: string, user_id: number) => {
    cellCallStore.listFilter = { enable: true, major_user_id: [user_id], group_id: [], search: phone };
    history.push('/calls/list');
};

const checkboxStyle = { padding: '0 0.5rem' };

const CallEventsTableBody = (props: CellCallTableBodyProps) => {
    const { callEventsList } = props;
    if (!callEventsList.length) {
        return null;
    }

    return (
        <Fragment>
            <TableBody>
                {callEventsList.map(callEvent => {
                    const {
                        call_event_id,
                        description,
                        title,
                        callTimeMin,
                        callTimeMax,
                        assign_users_ids,
                        assign_users,
                        item_type,
                        callHasReleased,
                        call
                    } = callEvent;

                    const phones =
                        callEvent.item_type === CALL_EVENT_ITEM_TYPE_CONTACT
                            ? callEvent.contact.phones.slice(0, 1)
                            : [
                                  {
                                      phone_id: 0,
                                      phone: callEvent.estate.ownerPhones?.length ? callEvent.estate.ownerPhones[0].phone : ''
                                  }
                              ];

                    return (
                        <TableRow key={call_event_id}>
                            <TableCell>
                                <CallEventLink callEvent={{ call_event_id, callTimeMin, callTimeMax, callHasReleased }} />
                            </TableCell>

                            <TableCell>
                                {callEvent.item_type === CALL_EVENT_ITEM_TYPE_CONTACT ? (
                                    <ContactLinkWithPreview contact={callEvent.contact} />
                                ) : (
                                    <Box display="flex">
                                        <Tooltip title="Собственник">
                                            <Icon name="male" color="brown" />
                                        </Tooltip>
                                        <EstateLinkWithPreview estate={callEvent.estate} base={ESTATE_BASE_OWNERS} />
                                    </Box>
                                )}
                                {(title || description) && (
                                    <Fragment>
                                        <br />
                                        <Popup
                                            size="small"
                                            trigger={
                                                <span>
                                                    {title.slice(0, 30)}
                                                    {title.length > 30 ? '...' : ''}
                                                    {description && <Icon name="comment" color="grey" />}
                                                </span>
                                            }
                                        >
                                            <List>
                                                <List.Item>
                                                    <List.Header>{title}</List.Header>
                                                    <List.Description>{description}</List.Description>
                                                </List.Item>
                                            </List>
                                        </Popup>
                                    </Fragment>
                                )}
                            </TableCell>

                            <TableCell>
                                <UsersLinkList usersList={assign_users} />
                            </TableCell>

                            <TableCell>
                                <List>
                                    <PhonesList phones={phones} />
                                </List>
                                {!callHasReleased && (
                                    <Checkbox
                                        label="Звонок состоялся"
                                        onClick={callEventStore.setCallEventHasReleased.bind(callEventStore, call_event_id)}
                                        checked={Boolean(callHasReleased)}
                                        style={checkboxStyle}
                                    />
                                )}
                                {callHasReleased && call && (
                                    <Fragment>
                                        <DownloadLink {...call} />
                                        <span>{secondsToMS(Math.round((call.releaseTime - call.answerTime) / 1000))}</span>
                                    </Fragment>
                                )}
                                {callHasReleased && !call && phones.length > 0 && (
                                    <Button
                                        basic
                                        size="small"
                                        onClick={setCellCallFilter.bind(this, phones[0].phone, assign_users_ids[0])}
                                        variant="text"
                                        startIcon={<SearchIcon />}
                                    >
                                        Список звонков
                                    </Button>
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Fragment>
    );
};

export default CallEventsTableBody;
