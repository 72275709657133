import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Input } from 'semantic-ui-react';

import exportStore from '~/stores/export/exportStore';
import config from '~/config/ui.config';
import authStore from '~/stores/authStore';
import estateStore from '~/stores/estateStore';
import commonStore from '~/stores/commonStore';
import contactStore from '~/stores/contactStore';
import { checkValidExportHelper } from '~/stores/helpers/export.store.helpers';

import { AVITO_EXPORT_BASE_ID, CIAN_EXPORT_BASE_ID } from '~/types/exports.types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Grid from '~ui/Grid';
import Checkbox from '~ui/Checkbox';
import UserAvatar from '~ui/UserAvatar';

import { HandleChangeType } from '~/components/Items/HandlerEditingBlockWrapper';
import ExportMajorUsers from '../SubBlocks/ExportMajorUsers';
import ListError from '~/components/ListError';
import ButtonGroup from '~/components/Base/ButtonGroup';
import LoaderAwait from '~/components/Base/LoaderAwait';

import AvitoXlTariffs from './AvitoXlTariffs';
import ExportExpireTime from './ExportExpireTime';
import ListItemTitle from './ExportListItemTitle';

type ExportBasesEditingProps = {
    item_id: number;
    type: number;
    propertyType: number;
};

const DISABLE_EXPORT_VALUE = 0;

@observer
class ExportBasesEditing extends Component<ExportBasesEditingProps, { exportValidErrors: Array<string> }> {
    state = {
        exportValidErrors: []
    };

    componentDidMount() {
        const { item_id, type, propertyType } = this.props;
        const { item: estate } = estateStore.getItem(item_id);
        const exportingBases = exportStore.filterTariffs(type, propertyType, estate);
        const { item } = exportStore.getItem(item_id);

        if (item) {
            const { bases, externalStatuses } = item;

            const exportedEstate = {
                estate_id: item_id,
                bases: exportingBases.map(({ export_base_id }) => {
                    const estateBase = exportStore.findBase(bases, export_base_id);

                    return {
                        export_base_id,
                        price_id: estateBase ? estateBase.price_id : 0,
                        extra: estateBase && estateBase.extra ? true : null,
                        bet: estateBase && estateBase.bet ? Number(estateBase.bet) : null,
                        title: estateBase && estateBase.title ? String(estateBase.title) : null,
                        expireTime: estateBase ? Number(estateBase.expireTime) : 0
                    };
                }),
                externalStatuses
            };

            exportStore.setEditingItem(item_id, exportedEstate);

            const { contact_id } = estateStore.getItem(this.props.item_id).item || {};
            if (contact_id) {
                contactStore.fetchItem(contact_id);
            }
        }
    }

    checkValidExport = async (export_base_id: number): Promise<boolean> => {
        this.setState({ exportValidErrors: [] });

        const { contact_id } = estateStore.getItem(this.props.item_id).item || {};

        let contact = null;
        if (contact_id) {
            contact = contactStore.getItem(contact_id).item;
        }

        const errors = await checkValidExportHelper(estateStore.getItem(this.props.item_id).item, export_base_id, contact);

        if (errors.length) {
            this.setState({ exportValidErrors: errors });
            return false;
        }

        return true;
    };

    handleChangeTariff: HandleChangeType = async (event, { name: export_base_id, value }) => {
        if (value === DISABLE_EXPORT_VALUE || (await this.checkValidExport(Number(export_base_id)))) {
            exportStore.changeTariff(this.props.item_id, Number(export_base_id), Number(value));
        }
    };

    handleChangeExtra = async (event: React.SyntheticEvent, { name: export_base_id, checked }: { name: string; checked: boolean }) => {
        if (await this.checkValidExport(Number(export_base_id))) {
            exportStore.changeExtra(this.props.item_id, Number(export_base_id), checked);
        }
    };

    handleChangeBet = (evt: React.SyntheticEvent, { value, name: export_base_id }: { value: string; name: number }) => {
        exportStore.changeBet(this.props.item_id, export_base_id, Number(value.replace(/\D/g, '')));
    };

    handleChangeTitle = (evt: React.SyntheticEvent, { value, name: export_base_id }: { value: string; name: number }) => {
        const titleClear = value.replace(/[: .,;!?-]/g, '');
        if (titleClear.length <= 33) {
            exportStore.changeTitle(this.props.item_id, export_base_id, value);
        }
    };

    render() {
        const { exportValidErrors } = this.state;
        const { item_id, type, propertyType } = this.props;

        const { item: estate } = estateStore.getItem(item_id);
        const { exclusiveDealId, contact_id } = estate || {};

        const exportingBases = exportStore.filterTariffs(type, propertyType, estate);
        const { item, editingItem, errors } = exportStore.getItem(item_id);

        const { bases } = editingItem;

        if (!bases) {
            return null;
        }

        let allCost = 0;

        if (!item) {
            return null;
        }

        let loadingItem = false;
        if (contact_id) {
            loadingItem = contactStore.getItem(contact_id).loadingItem;
        }
        const canPushHugePrice = authStore.currentUser.access.enableApplyingPremium;

        return (
            <Fragment>
                {exportValidErrors && exportValidErrors.length > 0 && <ListError errors={exportValidErrors} />}
                {loadingItem && <LoaderAwait active={true} />}

                {errors && <ListError errors={errors} />}
                <List dense disablePadding>
                    {exportingBases
                        .filter(({ tariff }) => tariff.enable)
                        .map(exportingBase => {
                            const { export_base_id, name, image, tariff, regionName } = exportingBase;
                            const foundBase = bases.find(base => base.export_base_id === export_base_id);

                            const { price_id, extra, expireTime, bet, title } = foundBase;
                            const { prices } = tariff;

                            const priceIndex = prices.findIndex(price => price.price_id === price_id);
                            const price = typeof priceIndex === 'number' ? prices[priceIndex] : null;

                            let cost = 0;
                            let dailyCost = 0;
                            let minDays = 1;

                            if (price) {
                                cost += price.dailyPrice;

                                if (extra && price) {
                                    cost += price.extraPrice;
                                }

                                minDays = price.minDays;
                                dailyCost = cost / minDays;
                            }

                            if (bet) {
                                cost += Number(bet);
                                dailyCost += Number(bet);
                            }

                            const tariffsBtns = exportStore.matchTariffsBtns(item_id, prices, export_base_id);

                            allCost += Number(dailyCost.toFixed(2));

                            return (
                                <ListItem key={export_base_id} disableGutters={commonStore.isMobile} divider>
                                    <ListItemAvatar>
                                        <UserAvatar
                                            name={name}
                                            src={image ? `${config.publicUrl}${image}` : null}
                                            size={36}
                                            boring="ring"
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        style={{ margin: 0 }}
                                        primary={
                                            <ListItemTitle
                                                estate_id={item_id}
                                                export_base_id={export_base_id}
                                                exportingBase={exportingBase}
                                                cost={cost}
                                                minDays={minDays}
                                            />
                                        }
                                        secondary={
                                            <Grid stackable spacing={0}>
                                                <Grid.Column width={9}>
                                                    <ButtonGroup
                                                        // Запрещаем менять тариф агенту, если включен топ или крутая платная услуга
                                                        buttonSet={[
                                                            [DISABLE_EXPORT_VALUE, 'нет', canPushHugePrice ? false : priceIndex > 0],
                                                            ...tariffsBtns
                                                        ]}
                                                        name={export_base_id}
                                                        value={price_id}
                                                        handleChange={this.handleChangeTariff}
                                                        disabled={!exportStore.isEnableExportBase(item_id, export_base_id)}
                                                        stackable={tariffsBtns.length > 1 && commonStore.isMobile}
                                                    />
                                                    {export_base_id === CIAN_EXPORT_BASE_ID && price_id > 0 && (
                                                        <Fragment>
                                                            {price && !['Закрытая База'].includes(price.name) && (
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Ставка"
                                                                    size="mini"
                                                                    value={bet || ''}
                                                                    name={export_base_id}
                                                                    onChange={this.handleChangeBet}
                                                                    className="crm-Estate__export_bet"
                                                                    disabled={!canPushHugePrice}
                                                                />
                                                            )}

                                                            {price && ['Топ-3', 'Премиум'].includes(price.name) && (
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Заголовок"
                                                                    size="mini"
                                                                    value={title || ''}
                                                                    name={export_base_id}
                                                                    onChange={this.handleChangeTitle}
                                                                    className="crm-Estate__export_title"
                                                                    maxLength={33}
                                                                />
                                                            )}
                                                        </Fragment>
                                                    )}
                                                    {export_base_id === AVITO_EXPORT_BASE_ID && price_id > 0 && (
                                                        <AvitoXlTariffs estate_id={item_id} canPushHugePrice={canPushHugePrice} />
                                                    )}
                                                </Grid.Column>
                                                <Grid.Column width={3}>
                                                    {canPushHugePrice && price && price.extraName && (
                                                        <Checkbox
                                                            name={String(export_base_id)}
                                                            label={price.extraName}
                                                            checked={Boolean(extra)}
                                                            onChange={this.handleChangeExtra}
                                                        />
                                                    )}
                                                </Grid.Column>
                                            </Grid>
                                        }
                                    />
                                    {!commonStore.isMobile && (
                                        <ExportExpireTime expireTime={expireTime} export_base_id={export_base_id} estate_id={item_id} />
                                    )}
                                </ListItem>
                            );
                        })}
                </List>
                <Grid stackable>
                    <Grid.Column width={9}>
                        <ExportMajorUsers item_id={item_id} editing />
                    </Grid.Column>

                    <Grid.Column width={3}>
                        Итого: <b className={exclusiveDealId ? 'crm-Estate__export_isExternal' : ''}>{allCost.toLocaleString()}</b>&nbsp;
                        {Number(exclusiveDealId) > 0 && <b>0</b>} ₽/день
                    </Grid.Column>
                </Grid>
            </Fragment>
        );
    }
}

export default ExportBasesEditing;
