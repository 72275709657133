import './leftMenu.less';

import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Icon, Button } from 'semantic-ui-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cs from 'classnames';

import { ACCESS_READ, ACCESS_SELF } from '~/types/access.types';

import commonStore from '~/stores/commonStore';

import history from '../../history';
import authStore from '~/stores/authStore';

import LeftMenuMobile from './LeftMenuMobile';
import { MenuTree } from '~/components/LeftMenu/menu-tree';

import ExpandedLeftMenu from './ExpandedLeftMenu';
import CollapsedLeftMenu from './CollapsedLeftMenu';
import chatStore from '~/stores/chatStore';

type LeftMenuState = {
    activeItem: string;
};

@observer
export default class LeftMenu extends React.Component<{ fullSize: boolean }, LeftMenuState> {
    state = {
        activeItem: history.location.pathname
    };

    handleItemClick = (event: React.SyntheticEvent, { name }: any) => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({ activeItem: name });

        if (commonStore.showMenu) {
            commonStore.toggleMenu();
        }

        history.push(name);
    };

    toggleVisibility = (event: React.SyntheticEvent) => {
        event.preventDefault();
        commonStore.toggleMenu();
    };

    handleToggleExpandedMenu = () => {
        commonStore.toggleExpanded();
    };

    render() {
        const MenuItemsFiltered = MenuTree(chatStore)
            .filter(({ access, accessPermission }) => {
                return authStore.matchAccess(access, ACCESS_READ, accessPermission || ACCESS_SELF);
            })
            .map(({ icon, title, access, src, submenu }) => {
                return {
                    icon,
                    title,
                    src,
                    submenu: submenu
                        ? submenu.filter(({ access, accessPermission }) =>
                              authStore.matchAccess(access, ACCESS_READ, accessPermission || ACCESS_SELF)
                          )
                        : []
                };
            });

        const { activeItem } = this.state;
        const { fullSize } = this.props;
        const { expandedMenu, isMobile, showMenu: visible } = commonStore;

        if (isMobile) {
            return (
                <LeftMenuMobile>
                    <ExpandedLeftMenu MenuItems={MenuItemsFiltered} activeItem={activeItem} handleItemClick={this.handleItemClick} />
                </LeftMenuMobile>
            );
        }

        return (
            <Fragment>
                <nav
                    className={cs({
                        'crm-leftMenu': true,
                        'crm-leftMenu_visible': visible,
                        'crm-Main__opacity': fullSize,
                        'crm-Print__hidden': true
                    })}
                >
                    <Link className="crm-leftMenu__logo" to="/">
                        <span className="crm-leftMenu__logo_item">
                            <img src="/static/brand/brand-min.png" alt="agentbase.ru" />
                        </span>

                        <div className="crm-leftMenu__close">
                            <Button basic icon="chevron left" onClick={this.toggleVisibility} />
                        </div>
                    </Link>

                    {expandedMenu || visible ? (
                        <PerfectScrollbar className={cs({ 'crm-leftMenu__scrollBox': true })}>
                            <ExpandedLeftMenu
                                MenuItems={MenuItemsFiltered}
                                activeItem={activeItem}
                                handleItemClick={this.handleItemClick}
                            />
                        </PerfectScrollbar>
                    ) : (
                        <CollapsedLeftMenu MenuItems={MenuItemsFiltered} activeItem={activeItem} handleItemClick={this.handleItemClick} />
                    )}

                    <button
                        className="crm-leftMenu__expandSwitch"
                        onClick={this.handleToggleExpandedMenu}
                        title={`${expandedMenu ? 'Свернуть' : 'Развернуть'} меню`}
                    >
                        <Icon name={expandedMenu ? 'toggle on' : 'toggle off'} color="blue" />
                        {expandedMenu && ` свернуть`}
                    </button>
                </nav>

                {visible && <div className="crm-leftMenu__pusher" onClick={this.toggleVisibility} />}
            </Fragment>
        );
    }
}
