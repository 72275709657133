import fetchApi from '../common/fetchApi';
import { GQUserMainFields } from './userApi';
import {
    ChatType,
    ChatFilter,
    ChatTitle,
    NotReadMessage,
    ChatListType,
    AdsChatTitle,
    AdsChatType,
    ADS_CHAT_TYPES
} from '~/types/chat.types';
import { objectToGraphql } from '~/common/graphql';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { GQEstateMainFields } from './estateApi';
import { GQWAMessage, GQWhatsappContact } from '~/api/whatsappApi';
import { GQMainContactFields } from '~/api/contactsApi';
import { WhatsappChatTitle } from '~/types/whatsapp.types';

const GQChatFields = `
    message_id
    message
    sender_id
    messageType
    createTime
    deliveryTime
    readTime  
`;

export const sendPrivateMessage = async (chat_id: number, message: string): Promise<number> => {
    const graphql = `mutation { 
          sendPrivateMessage(chat_id: ${chat_id}, message: ${objectToGraphql(message)})
        }`;

    const data: { sendPrivateMessage: number } = await fetchApi.postGQ(graphql);
    return data.sendPrivateMessage;
};

export const sendAdsChatMessage = async (chatType: ChatListType, chat_id: number, message: string): Promise<number> => {
    const graphql = `mutation { 
          sendAdsChatMessage(platform: ${chatType}, chat_id: ${chat_id}, message: ${objectToGraphql(message)})
        }`;

    const data = await fetchApi.postGQ<{ sendAdsChatMessage: number }>(graphql);
    return data.sendAdsChatMessage;
};

const GQChatUser = `
    ${GQUserMainFields}
    onlineTime
    
    inner_id
    ads_user_id
    our_user_id
    name
    userUrl   
`;

export const fetchItem = async (item_id: number, chatType: ChatListType): Promise<ChatType> => {
    switch (chatType) {
        case 'users':
            const graphqlUsers = `{ fetchMessagesUser(item_id: ${item_id}){
                chat_id
                chatType
                messages {
                    ${GQChatFields}
                    sender {
                        ${GQChatUser}   
                    }
                }
              }
            }`;

            const dataUsers = await fetchApi.getGQ<{ fetchMessagesUser: ChatType }>(graphqlUsers);
            return dataUsers.fetchMessagesUser;

        case 'avito':
        case 'cian':
        case 'domclick':
            const graphqlAds = `{ fetchMessagesAds(platform: ${chatType}, item_id: ${item_id}){
                chat_id
                chatType
                messages {
                    ${GQChatFields}
                    sender {
                        ${GQChatUser}   
                    }
                }
              }
            }`;

            const dataAvito = await fetchApi.getGQ<{ fetchMessagesAds: ChatType }>(graphqlAds);
            return dataAvito.fetchMessagesAds;

        case 'whatsapp':
            const graphqlWA = `{ 
                  fetchChatMessagesWhatsapp(whatsapp_chat_id: ${item_id}){
                    chat_id
                    chatType
                    phone
                    messages {
                        ${GQWAMessage}    
                    }
                    waContact {
                        ${GQWhatsappContact}    
                    }
                  }
            }`;

            const dataWA = await fetchApi.getGQ<{ fetchChatMessagesWhatsapp: ChatType }>(graphqlWA);
            return dataWA.fetchChatMessagesWhatsapp;

        default:
            const error: never = chatType;
    }
};

const adsChatListGQFields = `
    ads_chat_id
    estate_id
    estate {
        ${GQEstateMainFields}
        avatarUrl
    }
    adsUser {
        name
        avatarUrl
        externalUrl
    }
    lastMessage {
        ${GQChatFields}
    }
    countUnreadMessages
`;

export const fetchAdsChatsByEstate = async (chatType: AdsChatType, estate_id: number): Promise<AdsChatTitle[]> => {
    const graphql = `{
        fetchAdsChatsByEstate(platform: ${chatType}, estate_id: ${estate_id}) {
            ${adsChatListGQFields}
        }
    }`;

    const data = await fetchApi.getGQ<{ fetchAdsChatsByEstate: AdsChatTitle[] }>(graphql);
    return data.fetchAdsChatsByEstate;
};

export const fetchWhatsappChatList = async (
    user_id: number,
    limit: number,
    offset: number,
    controller?: AbortController
): Promise<WhatsappChatTitle[]> => {
    const graphql = `{
          fetchWhatsappChatList(user_id: ${user_id}, limit: ${limit}, offset: ${offset}) {
            whatsapp_chat_id
            waContact {
                ${GQWhatsappContact}
            }
            phone
            lastMessage {
                message
                createTime
            }
            countUnreadMessages
            contact_id
            contact {
                ${GQMainContactFields}
            }
          }
        }`;

    const data = await fetchApi.getGQ<{ fetchWhatsappChatList: WhatsappChatTitle[] }>(graphql, controller);
    return data.fetchWhatsappChatList;
};

export const fetchAdsChatsList = async (limit: number, offset: number, { type, user_id }: ChatFilter): Promise<AdsChatTitle[]> => {
    const graphql = `{
          fetchAdsChatsList(platform: ${type}, user_id: ${user_id}, limit: ${limit}, offset: ${offset}) {
            ${adsChatListGQFields}
          }
        }`;

    const data = await fetchApi.getGQ<{ fetchAdsChatsList: AdsChatTitle[] }>(graphql);
    return data.fetchAdsChatsList;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    { type, user_id }: ChatFilter,
    controller?: AbortController
): Promise<{ list: ChatTitle[]; count: number }> => {
    if (ADS_CHAT_TYPES.includes(type)) {
        const list = await fetchAdsChatsList(limit, offset, { type, user_id });
        return {
            list,
            count: list.length
        };
    } else if (type === 'whatsapp') {
        const list = await fetchWhatsappChatList(user_id, limit, offset);
        return { list, count: list.length };
    } else {
        const graphql = `{
          fetchUserChatsList(user_id: ${user_id}, limit: ${limit}, offset: ${offset}) {
            chat_id
            user_id
            user {
                ${GQUserMainFields}
                onlineTime
            }
            lastMessage {
                ${GQChatFields}
            }
            countUnreadMessages
          }
        }`;

        const data: { fetchUserChatsList: ChatTitle[] } = await fetchApi.getGQ(graphql, controller);
        return { list: data.fetchUserChatsList, count: data.fetchUserChatsList.length };
    }
};

export const fetchNotReadMessages = async (): Promise<NotReadMessage[]> => {
    const graphql = `{
          fetchNotReadMessages {
            chat_id
            message_id
            message
            sender_id
            messageType
            sender {
                ${GQChatUser}   
            }
            estate_id
            avito_chat_id
            createTime
          }
        }`;

    const data: { fetchNotReadMessages: NotReadMessage[] } = await fetchApi.getGQ(graphql);
    return data.fetchNotReadMessages;
};

export const readChatMessage = async (message_id: number): Promise<number> => {
    const graphql = `mutation { 
          readChatMessage(message_id: ${message_id})
        }`;

    const data: { readChatMessage: number } = await fetchApi.postGQ(graphql);
    return data.readChatMessage;
};

export const readAllNotReadMessages = async (): Promise<boolean> => {
    const graphql = `mutation { 
          readAllNotReadMessages
        }`;

    const data: { readAllNotReadMessages: boolean } = await fetchApi.postGQ(graphql);
    return data.readAllNotReadMessages;
};

export const fetchUserChatId = async (user_id: number): Promise<number> => {
    const graphql = `{
          fetchUserChatId(user_id: ${user_id})
        }`;

    const data = await fetchApi.getGQ<{ fetchUserChatId: number }>(graphql);
    return data.fetchUserChatId;
};
