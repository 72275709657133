import React from 'react';

import { ChatListType } from '~/types/chat.types';

import UserChatHeader from './UserChatHeader';
import EstateChatHeader from './EstateChatHeader';
import WhatsappChatHeader from './WhatsappChatHeader';

const ChatHeader = ({ item_id, chatType }: { item_id: number; chatType: ChatListType }) => {
    switch (chatType) {
        case 'users':
            return <UserChatHeader item_id={item_id} />;
        case 'avito':
        case 'cian':
        case 'domclick':
            return <EstateChatHeader item_id={item_id} />;
        case 'whatsapp':
            return <WhatsappChatHeader item_id={item_id} />;
        default:
            const error: never = chatType;
    }
};

export default ChatHeader;
