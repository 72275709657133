import { createBrowserHistory, History, Location } from 'history';

export type HistoryLocationState = Partial<{
    modal: boolean;
    dialog: boolean;
    from: {
        storeName: string;
        item_id: number;
    };
    callback: {
        storeName: string;
        method: string;
        item_id?: number;
    };
    leftMenuIsOpen: boolean;
    editingData: object;
    switchFromTab: boolean;
    editing: boolean;
}>;

export type HistoryLocation = Location<HistoryLocationState>;

export default createBrowserHistory() as History<Partial<HistoryLocationState>>;
