import { observable, action, runInAction } from 'mobx';
import uiConfig from '../config/ui.config';
import * as crmChangesApi from '~/api/crmChangesApi';
import { throttle } from '~/common/throttle';

export type CRMChange = {
    releaseTime: number;
    title: string;
};

class CommonStore {
    @observable
    showMenu = false;

    @observable
    screenIsWide: boolean = window.matchMedia('(min-width: 1540px)').matches;

    screenIsShort: boolean = window.matchMedia('(max-height: 825px)').matches;

    @observable
    expandedMenu: boolean;

    matchExpandedMenuStatus(): boolean {
        return window.matchMedia('(min-width: 1440px)').matches;
    }

    defaultPageSize = 10;

    @observable
    isMobile: boolean;
    @observable
    isLowHeight: boolean;

    private checkWindowSize() {
        this.isMobile = window.matchMedia('(max-width: 768px)').matches;
        this.isLowHeight = this.isMobile && window.matchMedia('(max-height: 668px)').matches;
        this.expandedMenu = this.matchExpandedMenuStatus();

        if (window.matchMedia('(min-height: 520px) and (orientation:landscape)').matches) {
            this.defaultPageSize = uiConfig.defaultPageSize;
        } else if (window.matchMedia('(min-height: 400px) and (orientation:landscape)').matches) {
            this.defaultPageSize = 20;
        }
    }

    throttleWindowSize = throttle(() => {
        this.checkWindowSize();
    }, 100);

    constructor() {
        this.checkWindowSize();

        if ('ResizeObserver' in window) {
            // @ts-ignore
            const resizeObserver = new window.ResizeObserver(this.throttleWindowSize);
            resizeObserver.observe(document.getElementById('root'));
        } else {
            // @ts-ignore
            window.onresize = this.throttleWindowSize;
        }
    }

    @action
    toggleMenu(menuEnabled?: boolean) {
        if (typeof menuEnabled === 'boolean') {
            this.showMenu = menuEnabled;
        } else {
            this.showMenu = !this.showMenu;
        }
    }

    @action
    toggleExpanded() {
        this.expandedMenu = !this.expandedMenu;
    }

    @observable
    fullSizePage = false;

    @action
    toggleFullSizePage = () => {
        this.fullSizePage = !this.fullSizePage;
        this.expandedMenu = false;
    };

    @action
    restorePageSize = () => {
        this.fullSizePage = false;
        this.expandedMenu = this.matchExpandedMenuStatus();
    };

    @observable
    loadingCRMChanges = false;
    crmChangesList: CRMChange[] = [];

    async fetchCRMChanges() {
        this.loadingCRMChanges = true;

        const history = await crmChangesApi.fetchCRMChanges();

        runInAction(() => {
            this.loadingCRMChanges = false;
            this.crmChangesList = history;
        });
    }
}

export default new CommonStore();
