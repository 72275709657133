import React from 'react';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { adjustDirection } from '~/stores/helpers/adjustDirection.helpers';
import { TableHead, TableRow, TableCell } from '~ui/Table';

const ShowingsTableHeader = ({
    orderBy,
    orderDirection,
    handleSort
}: {
    orderBy: string;
    orderDirection: OrderDirectionType;
    handleSort: (name: string) => void;
}) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell
                    sortDirection={orderBy === 'showingTime' ? adjustDirection(orderDirection) : false}
                    onClick={() => handleSort('showingTime')}
                >
                    Дата
                </TableCell>
                <TableCell
                    sortDirection={orderBy === 'user_id' ? adjustDirection(orderDirection) : false}
                    onClick={() => handleSort('user_id')}
                >
                    Сотрудник
                </TableCell>
                <TableCell
                    sortDirection={orderBy === 'contact_id' ? adjustDirection(orderDirection) : false}
                    onClick={() => handleSort('contact_id')}
                >
                    Клиент
                </TableCell>
                <TableCell />
                <TableCell
                    sortDirection={orderBy === 'estate_id' ? adjustDirection(orderDirection) : false}
                    onClick={() => handleSort('estate_id')}
                >
                    Объект
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export default ShowingsTableHeader;
