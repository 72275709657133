import { UserLinkType } from './users.types';
import { EstateLinkType } from './estate.types';
import { WAChatMessage, WhatsappChatContactType, WhatsappChatTitle } from './whatsapp.types';
import { CianMessage, CianUser } from './cian_chat.types';

export type Message = {
    message_id: number;
    sender_id: number;
    message: string;
    messageType: number;
    createTime: number;
    deliveryTime: number | null;
    readTime: number | null;
    enable: boolean;
};

export type ChatMessageInBase = Message & {
    chat_id: number;
};

export type ChatMessage = ChatMessageInBase & {
    sender: UserLinkType;
};

export type AvitoMessageInBase = Message & {
    avito_chat_id: number;
    avito_message_id: string;
};

export type AvitoMessage = AvitoMessageInBase & {
    sender: AvitoUser;
};

export type AdsMessage = Message & {
    sender: AdsChatUser;
};

export type NotReadMessage = {
    chat_id: number;
    message_id: number;
    message: string;
    sender_id: number;
    sender: UserLinkType | CianUser | AvitoUser;
    messageType: number;
    createTime: number;
    estate_id: number | null;
    avito_chat_id: number | null;
};

export type ChatType = {
    chat_id: number;
} & (
    | {
          chatType: 'users';
          messages: ChatMessage[];
      }
    | {
          chatType: 'avito';
          messages: AvitoMessage[];
      }
    | {
          chatType: 'cian';
          messages: CianMessage[];
      }
    | {
          chatType: 'domclick';
          messages: AdsMessage[];
      }
    | {
          chatType: 'whatsapp';
          phone: string;
          messages: WAChatMessage[];
          waContact: WhatsappChatContactType;
      }
);

export type UserChatTitle = {
    chat_id: number;
    user_id: number;
    user: UserLinkType & { onlineTime: number };
    lastMessage: Message;
    countUnreadMessages: number;
};

export type AvitoUser = {
    avito_chat_user_id: number;
    avito_chat_id: number;
    inner_id: number;
    name: string;
    avatarUrl: string;
    userUrl: string;
};

export type AdsChatUser = {
    // senderType: string;
    ads_user_id: number;
    our_user_id: number | null;
    name: string;
    avatarUrl: string;
    externalUrl: string;
};

export type AdsChatTitle = {
    ads_chat_id: number;
    estate_id: number;
    estate: EstateLinkType & { avatarUrl: string };
    adsUser: AdsChatUser;
    lastMessage: Message;
    countUnreadMessages: number;
};

// export type CianChatTitle = {
//     cian_chat_id: number;
//     estate_id: number;
//     estate: EstateLinkType & { avatarUrl: string };
//     cianUser: CianUser;
//     lastMessage: Message;
//     countUnreadMessages: number;
// };

export type ChatTitle = UserChatTitle | AdsChatTitle | WhatsappChatTitle;

export type AdsChatType = 'avito' | 'cian' | 'domclick';
export type ChatListType = AdsChatType | 'users' | 'whatsapp';

export const ADS_CHAT_TYPES: readonly ChatListType[] = ['avito', 'cian', 'domclick'] as const;

export type ChatFilter = {
    type: ChatListType;
    user_id: number;
};
