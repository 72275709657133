import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Icon } from 'semantic-ui-react';

import { SHOWING_TYPE_INNER, SHOWING_TYPE_MLS, SHOWING_TYPE_OUTER } from '~/types/showings.types';
import showingStore from '~/stores/showingStore';

import EditingBlockWrapper from '~/components/Items/EditingBlockWrapper';
import { EditingWrappedProps } from '~/components/Items/EditingBlockWrapper';

import EstateAndContactEditing from '../EditBlocks/EstateAndContactEditing';

import ContactLinkWithPreview from '~/components/Lists/Contacts/ContactLinkWithPreview';
import EstateLinkWithPreview from '~/components/Lists/Estate/EstateLinkWithPreview';
import TagsList from '~/components/Items/Contact/ShowBlocks/SubBlocks/TagsList';

const EstateAndContactShow = (props: EditingWrappedProps) => {
    const { item_id, editing } = props;
    const { item, loadingItem } = showingStore.getItem(item_id);

    if (!item || loadingItem) {
        return null;
    }

    const { estate, contact, tags_ids, estateOuterUrl, showingType } = item;

    return !editing ? (
        <Fragment>
            <Card fluid color="yellow">
                <Card.Content>
                    <Header size="tiny">Клиент (Посредник)</Header>
                    <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column>{contact && <ContactLinkWithPreview contact={contact} />}</Grid.Column>
                        </Grid.Row>
                        {tags_ids.length > 0 && (
                            <Grid.Row>
                                <Grid.Column>
                                    <TagsList tags_ids={tags_ids} />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                    </Grid>
                </Card.Content>
            </Card>

            <Card fluid color="red">
                <Card.Content>
                    <Header size="tiny">Объект</Header>
                    <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column>
                                {estate && showingType === SHOWING_TYPE_INNER && <EstateLinkWithPreview estate={estate} />}
                                {/*{estate && showingType === SHOWING_TYPE_MLS && <EstateLinkWithPreview estate={estate} isMls={true} />}*/}
                                {showingType === SHOWING_TYPE_OUTER && (
                                    <a target="_blank" href={estateOuterUrl} rel="noreferrer">
                                        Внешний объект <Icon name="external" />
                                    </a>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
            </Card>
        </Fragment>
    ) : (
        <Card fluid color="yellow">
            <Card.Content>
                <EstateAndContactEditing item_id={item_id} editing={editing} {...item} />
            </Card.Content>
        </Card>
    );
};

export default EditingBlockWrapper(showingStore, observer(EstateAndContactShow));
