import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Menu, Form, Dropdown } from 'semantic-ui-react';

import tagStore from '~/stores/tagStore';
import showingStore from '~/stores/showingStore';
import estateStore from '~/stores/estateStore';

import { ListFilterWrapperProps } from '../ListFilterWrapper';
import ListFilterWrapper from '../ListFilterWrapper';
import ItemDropdown from '~/components/Items/Common/ItemDropdow';

@observer
class FilterShowings extends Component<ListFilterWrapperProps> {
    handleTagsIds = (event: React.SyntheticEvent, { value }: { value: Array<number> }) => {
        showingStore.changeFilter('tags_ids', value);
    };

    render() {
        const { allTagsDropdown, loadingFetchAllTags } = tagStore;

        const { tags_ids, estate_id } = showingStore.listFilter;

        return (
            <>
                <Menu.Item className="crm-List__filter_dropdown">
                    <Form size="mini">
                        <Dropdown
                            multiple={true}
                            selection
                            options={allTagsDropdown}
                            search
                            placeholder="Тэги контактов"
                            value={tags_ids}
                            noResultsMessage="Тэг не найден"
                            onChange={this.handleTagsIds}
                            loading={loadingFetchAllTags}
                        />
                    </Form>
                </Menu.Item>
                <Menu.Item className="crm-List__filter_dropdown">
                    <Form size="mini">
                        <ItemDropdown
                            name="estate_id"
                            store={estateStore}
                            item_id={estate_id}
                            onChange={this.props.handleChange}
                            placeholder="Выбрать объект"
                            noResultsMessage="Объект не найден"
                        />
                    </Form>
                </Menu.Item>
            </>
        );
    }
}

export default ListFilterWrapper(showingStore, FilterShowings);
