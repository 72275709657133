import React, { Fragment, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import MaterialMenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { MenuItemSubmenuType, MenuItemType } from './menu-tree';
import Badge from '@material-ui/core/Badge';

export type CollapsedMenuProps = {
    activeItem: string;
    handleItemClick: (event: React.SyntheticEvent, data: any) => void;
    MenuItems: MenuItemType[];
};

const matchSubmenuBadge = (submenu: MenuItemSubmenuType[]): number | null => {
    return submenu.reduce((acc, menu) => {
        if (menu.badgeCount) {
            return Number(acc) + menu.badgeCount;
        }
        return acc;
    }, null) as number | null;
};

class CollapsedLeftMenu extends PureComponent<CollapsedMenuProps, { openedItem: string; anchorEl: null | HTMLElement }> {
    state = {
        openedItem: '',
        anchorEl: null
    };

    handleClickSubmenu = (event: React.SyntheticEvent, object: any) => {
        this.handleCloseDropdown();
        this.props.handleItemClick(event, object);
    };

    handleOpenDropdown = (event: React.SyntheticEvent<HTMLElement>, { title }: { title: string }) => {
        this.setState({ anchorEl: event.currentTarget, openedItem: title });
    };

    handleCloseDropdown = () => {
        this.setState({ anchorEl: null, openedItem: '' });
    };

    render() {
        const { activeItem, handleItemClick, MenuItems } = this.props;
        const { openedItem, anchorEl } = this.state;

        const { submenu, icon, title: titleHeader } = MenuItems.find(({ title }) => openedItem === title) || {};

        return (
            <Fragment>
                <PerfectScrollbar options={{ suppressScrollX: true }} className="crm-leftMenu__menu_collapsed">
                    <Menu icon="labeled" size="mini" vertical className="crm-leftMenu__menu">
                        <Menu.Item as={Link} to="/" title="Начальный экран" name="/" active={activeItem === '/'} onClick={handleItemClick}>
                            <Icon name="home" />
                            Главная
                        </Menu.Item>

                        {MenuItems.map(({ icon, title, src, submenu }) => (
                            <Menu.Item
                                key={src}
                                title={title}
                                name={src}
                                active={Boolean(submenu && submenu.find(({ src }) => src === activeItem))}
                                onClick={this.handleOpenDropdown}
                            >
                                <Icon name={icon} />
                                <Badge badgeContent={matchSubmenuBadge(submenu)} color="secondary">
                                    {title}
                                </Badge>
                            </Menu.Item>
                        ))}
                    </Menu>
                </PerfectScrollbar>

                <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} transition placement="right">
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps} style={{ transformOrigin: 'left middle' }}>
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleCloseDropdown}>
                                    <div>
                                        {submenu &&
                                            submenu.map(({ title, src, createPath, badgeCount }, index) => (
                                                <div key={title}>
                                                    {index === 0 && (
                                                        <Link to={src} style={{ color: 'inherit' }}>
                                                            <MaterialMenuItem
                                                                onClick={event =>
                                                                    this.handleClickSubmenu(event, {
                                                                        to: src,
                                                                        name: src
                                                                    })
                                                                }
                                                            >
                                                                <b style={{ fontWeight: 500 }}>
                                                                    <Icon name={icon} /> {titleHeader}
                                                                </b>
                                                            </MaterialMenuItem>
                                                            <Divider />
                                                        </Link>
                                                    )}
                                                    <Link to={src} style={{ color: 'inherit' }}>
                                                        <Badge badgeContent={badgeCount} color="secondary" style={{ width: '100%' }}>
                                                            <MaterialMenuItem
                                                                onClick={event => this.handleClickSubmenu(event, { to: src, name: src })}
                                                                className="crm-leftMenu__menu_withPlus"
                                                                style={{ width: '100%' }}
                                                            >
                                                                {title}
                                                            </MaterialMenuItem>
                                                        </Badge>
                                                    </Link>
                                                </div>
                                            ))}
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Fragment>
        );
    }
}

export default CollapsedLeftMenu;
