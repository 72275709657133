import React, { Fragment } from 'react';
import { ADS_CHAT_TYPES, AdsMessage, AvitoMessage, ChatListType, ChatMessage as ChatMessageType } from '~/types/chat.types';
import { UserLinkState } from '../Lists/Users/UserLink';
import isEqual from '~/common/isEqual';

import ChatMessage from './ChatMessage';
import { CianMessage } from '~/types/cian_chat.types';

export const ChatMessagesList = React.memo(
    ({
        messages,
        chatType,
        user_id
    }: {
        chatType: ChatListType;
        messages: (ChatMessageType | AvitoMessage | CianMessage | AdsMessage)[];
        user_id: number;
    }) => {
        const isFromAds = ADS_CHAT_TYPES.includes(chatType);

        return (
            <>
                {messages.map(({ message_id, message, createTime, sender, messageType, deliveryTime, readTime }) => {
                    const our_user_id = sender['user_id'] || sender['our_user_id'];

                    return (
                        <ChatMessage
                            key={message_id}
                            avatar={sender.avatarUrl}
                            message={message}
                            reverse={(isFromAds && our_user_id) || sender['user_id'] === user_id}
                            time={createTime}
                            name={sender['name'] || `${sender['firstName']} ${sender['lastName']}`}
                            hrefTo={isFromAds ? (our_user_id !== null ? UserLinkState(our_user_id) : sender['userUrl']) : null}
                            showName={chatType === 'cian' || chatType === 'domclick'}
                            deliveryTime={deliveryTime}
                            readTime={readTime}
                        />
                    );
                })}
            </>
        );
    },
    isEqual
);

export default ChatMessagesList;
