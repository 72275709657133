import React from 'react';
import { adjustDirection } from '~/stores/helpers/adjustDirection.helpers';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { TableHead, TableRow, TableCell } from '~ui/Table';

const CallRatesTableHeaders = ({
    orderBy,
    orderDirection,
    handleSort
}: {
    orderBy: string;
    orderDirection: OrderDirectionType;
    handleSort: (name: string) => void;
}) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell
                    sortDirection={orderBy === 'call_rate_id' ? adjustDirection(orderDirection) : false}
                    onClick={() => handleSort('call_rate_id')}
                >
                    Дата оценки
                </TableCell>
                <TableCell
                    sortDirection={orderBy === 'major_user_id' ? adjustDirection(orderDirection) : false}
                    onClick={() => handleSort('major_user_id')}
                >
                    Кто оценил(а)
                </TableCell>
                <TableCell sortDirection={orderBy === 'rate' ? adjustDirection(orderDirection) : false} onClick={() => handleSort('rate')}>
                    Оценка
                </TableCell>
                <TableCell>Кого оценил(а)</TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    );
};

export default CallRatesTableHeaders;
