import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';

type RoomsTypeProps = {
    roomsCount?: number;
    roomType?: number;
    onChange: (roomType: number) => void;
};

const RoomTypeEditing = ({ roomsCount, roomType, onChange }: RoomsTypeProps) => {
    const handleRoomType = (event: React.SyntheticEvent, { value, checked }: { value: number; checked: boolean }) => {
        if (Number(roomsCount) > 1) {
            roomType += (checked ? 1 : -1) * value;
        } else {
            roomType = checked ? value : null;
        }

        onChange(roomType);
    };

    if (Number(roomsCount) < 2 || Number(roomsCount) > 6) return null;

    return (
        <Form.Group>
            <Form.Field inline>
                <label className="crm-Estate__field_label">Тип комнат</label>
                <Checkbox
                    checked={roomType && (roomType === 1 || roomType === 3)}
                    value={1}
                    label={<label>смежная</label>}
                    onClick={handleRoomType}
                    className="crm-Estate__field_rightMargin"
                />
                <Checkbox checked={roomType && roomType > 1} value={2} label={<label>изолированная</label>} onClick={handleRoomType} />
            </Form.Field>
        </Form.Group>
    );
};

export default RoomTypeEditing;
