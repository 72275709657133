import { ContactLinkType } from './contacts.types';
import { EstateLinkType } from './estate.types';
import { UserLinkType } from './users.types';
import { DEAL_ESTATE_TYPE } from './deals.types';

export const SHOWING_TYPE_INNER = 1;
export const SHOWING_TYPE_MLS = 2;
export const SHOWING_TYPE_OUTER = 3;

export type ShowingLinkType = {
    readonly showing_id: number;
    showingTime: number;
};

export type ShowingInBaseType = ShowingLinkType & {
    estateOuterUrl: string | null;
    showingType: DEAL_ESTATE_TYPE;
    major_user_id: number;
    contact_id: number | null;
    estate_id: number | null;
    description: string;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type ShowingTableItem = ShowingLinkType & {
    tags_ids?: number[];
    contact_id: number | null;
    contact: ContactLinkType;
    estate_id: number | null;
    estate?: EstateLinkType;
    updateTime: number;
    major_user: UserLinkType;
    estateOuterUrl: string | null;
    showingType: DEAL_ESTATE_TYPE;
    major_user_id: number;
};

export type Showing = ShowingTableItem & {
    description: string;
    createTime: number;
    enable: boolean;
};

export type ShowingFilter = {
    estate_id: number;
    enable: boolean;
    group_id: number[];
    tags_ids: number[];
    major_user_id: number[];
};

export type SHOWING_ITEM_AVAILABLE = 'contact' | 'estate' | 'user';
