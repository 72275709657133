import React from 'react';
import { observer } from 'mobx-react';
import history from '~/history';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';
import { adjustDirection } from '~/stores/helpers/adjustDirection.helpers';

import Box from '@material-ui/core/Box';

import Table, { TableBody, TableCell, TableHead, TableRow } from '~ui/Table';
import SkeletonTable from '~ui/SkeletonTable';

import EstateTableAreaBlock from '~/components/Lists/Estate/SubBlocks/EstateTableAreaBlock';
import estateL10n from '~/L10n/estate.L10n';

import { EstateLinkState } from '~/components/Lists/Estate/EstateLink';
import ListFooter from '~/components/Lists/Common/ListFooterNew';
import MonoString from '~/components/Base/MonoString';

const LifeComplexApartmentsTable = ({ newbuilding_id }: { newbuilding_id: number }) => {
    const {
        loadingApartments,
        apartments,
        apartmentsPagination,
        apartmentsOrderBy,
        apartmentsOrderDirection,
        apartmentsCount,
        housesInfo
    } = lifeComplexStore.getItem(newbuilding_id).property;

    const { agentBonusValue, agentBonusType } = lifeComplexStore.getItem(newbuilding_id).item || { agentBonusType: 1, agentBonusValue: 0 };

    const handleOpen = (estate_id: number) => {
        history.push(EstateLinkState(estate_id, 'all', 'external'));
    };

    const handlePageChange = (activePage: number) => {
        lifeComplexStore.changeApartmentsPaginationPage(newbuilding_id, activePage);
    };

    const handlePageSizeChange = (pageSize: number) => {
        lifeComplexStore.changeApartmentsPaginationPageSize(newbuilding_id, pageSize);
    };

    const handleSort = (orderBy: string) => {
        lifeComplexStore.handleApartmentsSort(newbuilding_id, orderBy);
    };

    return (
        <Table compact size="small" style={{ position: 'relative' }}>
            <TableHead id="LifeComplexApartmentsTable">
                <TableRow>
                    <TableCell>Корпус</TableCell>
                    <TableCell
                        onClick={() => handleSort('roomsCount')}
                        sortDirection={apartmentsOrderBy === 'roomsCount' ? adjustDirection(apartmentsOrderDirection) : false}
                    >
                        Комнат
                    </TableCell>
                    <TableCell
                        onClick={() => handleSort('floorNumber')}
                        sortDirection={apartmentsOrderBy === 'floorNumber' ? adjustDirection(apartmentsOrderDirection) : false}
                    >
                        Этаж
                    </TableCell>
                    <TableCell
                        onClick={() => handleSort('totalArea')}
                        sortDirection={apartmentsOrderBy === 'totalArea' ? adjustDirection(apartmentsOrderDirection) : false}
                    >
                        Площадь
                    </TableCell>
                    <TableCell
                        onClick={() => handleSort('price')}
                        sortDirection={apartmentsOrderBy === 'price' ? adjustDirection(apartmentsOrderDirection) : false}
                    >
                        Цена
                    </TableCell>
                    <TableCell>Комиссия</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {loadingApartments && <SkeletonTable columns={6} rows={10} />}
                {!loadingApartments &&
                    apartments.map(
                        ({
                            estate_id,
                            newbuilding_house_id,
                            roomsCount,
                            floorNumber,
                            floorsCount,
                            price,
                            propertyType,
                            totalArea,
                            livingArea,
                            kitchenArea,
                            allRoomsArea
                        }) => (
                            <TableRow
                                key={estate_id}
                                hover
                                style={{ cursor: 'pointer' }}
                                title="Открыть карточку объекта"
                                onClick={() => handleOpen(estate_id)}
                            >
                                <TableCell>{housesInfo.find(house => house.newbuilding_house_id === newbuilding_house_id)?.name}</TableCell>
                                <TableCell>{roomsCount}</TableCell>
                                <TableCell>
                                    {floorNumber} / {floorsCount}
                                    {/*<EstateTableFloorAndHouseBlock*/}
                                    {/*    propertyType={propertyType}*/}
                                    {/*    floorsCount={floorsCount}*/}
                                    {/*    floorNumber={floorNumber}*/}
                                    {/*    materialType={1}*/}
                                    {/*    buildYear={2020}*/}
                                    {/*    buildingIsNotReadyYet={true}*/}
                                    {/*/>*/}
                                </TableCell>
                                <TableCell>
                                    <EstateTableAreaBlock
                                        propertyType={propertyType}
                                        totalArea={totalArea}
                                        livingArea={livingArea}
                                        kitchenArea={kitchenArea}
                                        allRoomsArea={allRoomsArea}
                                        roomsCount={roomsCount}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" flexDirection="column" style={{ gap: '0.25rem' }}>
                                        <MonoString>
                                            {price.toLocaleString()} {estateL10n.CURRENCY_TYPES_FIND(1)}
                                        </MonoString>
                                        <MonoString>
                                            {Number(totalArea) > 0 && `${Math.round(price / Number(totalArea)).toLocaleString()} за м²`}
                                        </MonoString>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    {agentBonusType === 2 && (
                                        <MonoString>
                                            {Math.round((agentBonusValue / 100) * price).toLocaleString()}
                                            {estateL10n.CURRENCY_TYPES_FIND(1)} ({agentBonusValue}%)
                                        </MonoString>
                                    )}
                                    {agentBonusType === 1 && (
                                        <MonoString>
                                            {agentBonusValue} {estateL10n.CURRENCY_TYPES_FIND(1)}
                                        </MonoString>
                                    )}
                                </TableCell>
                            </TableRow>
                        )
                    )}
            </TableBody>
            <ListFooter
                loading={loadingApartments}
                colSpan={5}
                pagination={apartmentsPagination}
                listCount={apartmentsCount || 0}
                pageChange={handlePageChange}
                pageSizeChange={handlePageSizeChange}
                hideGoToPage
                scrollToElementId="LifeComplexApartmentsTable"
            />
        </Table>
    );
};

export default observer(LifeComplexApartmentsTable);
